import React, { useState, useEffect, useRef } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { PELULUS } from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Container, Row, Col, Form } from 'react-bootstrap';
import Pagination from '../../components/Pagination';
import Swal from 'sweetalert2';
import useLocalStorage from '../../hooks/useLocalStorage';
import * as XLSX from 'xlsx';
import formatDate from '../formatDate';
import {
  faCheck,
  faBan,
  faDownload,
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import HashPwd from './HashPwd';

const ResetList = () => {
  const { auth } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const filterRef = useRef();
  const pageRef = useRef();

  const [resets, setResets] = useState([]);
  const [tempPwd, setTempPwd] = useState('');
  const [currentPage, setCurrentPage] = useLocalStorage('resetPage', 1);
  const axiosPrivate = useAxiosPrivate();
  const [recordsPerPage] = useState(10);

  const [filterStatus, setFilterStatus] = useLocalStorage(
    'resetStatus',
    'Belum diluluskan'
  );
  const [sort, setSort] = useState('asc');

  let content;
  let showContent;
  let trueContent;

  const generateRandomPwd = () => {
    let result = '';
    const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*(){}[]\\~';

    for (let i = 0; i < 14; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  };

  useEffect(() => {
    setTempPwd(generateRandomPwd());
  }, []);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getResets = async () => {
      try {
        const response = await axiosPrivate.get('/reset', {
          signal: controller.signal,
        });

        const requestData = response.data;
        const { data } = requestData;

        isMounted && setResets(data);
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching actions:', error);
          navigate('/login', { state: { from: location }, replace: true });
        }
      }
    };

    getResets();

    return () => {
      isMounted = false;
      if (!controller.signal.aborted) {
        controller.abort();
      }
    };
  }, [axiosPrivate, location, navigate]);

  const sendEmail = async (userName, email) => {
    try {
      const emailData = {
        userName: userName,
        email: email,
        tempPassword: tempPwd,
      };
      await axiosPrivate.post('/email/pwdEmail', emailData);
      console.log(emailData);
    } catch (err) {
      console.error(`${err}: Email is not sent`);
    }
  };

  // Change password in DB
  // The password will be expired on the next day at 12:00 AM
  const changePwdDB = async (userName) => {
    try {
      const today = new Date(); // get today's date
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);
      const data = {
        password: HashPwd(userName, tempPwd),
        pwdExpiry: tomorrow,
      };
      await axiosPrivate.patch(`/reset/${userName}`, data);
    } catch (error) {
      console.error('Error: ', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while approving the reset status.',
      });
    }
  };

  // Approve reset
  const approveReset = async (reset_id, userName, email) => {
    const status = 'Telah diluluskan';
    try {
      if (PELULUS.includes(auth?.userName)) {
        const response = await axiosPrivate.patch(
          `/resetApproved/${reset_id}`,
          {
            temp_pwd: HashPwd(userName, tempPwd),
            changed_time: new Date(),
            approver: auth?.userName,
            approved_time: new Date(),
            status: status,
          }
        );

        sendEmail(userName, email);

        Swal.fire({
          icon: 'success',
          title: 'Pemohonan diluluskan',
          text: `Pemohonan ${reset_id} telah diluluskan `,
        }).then(() => {
          if (response.status === 204) {
            window.location.reload();
          }
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Tiada akses',
          text: 'Anda tiada akses untuk memberi kelulusan',
        });
      }
    } catch (error) {
      console.error('Error updating status:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while approving the reset status.',
      });
    }
  };

  // Delete Reset
  const deleteReset = async (reset_id) => {
    try {
      if (PELULUS.includes(auth?.userName)) {
        const result = await Swal.fire({
          title: 'Tolak pemohonan ini?',
          text: 'Pemohonan reset ini akan ditolak dan dipadam jika diteruskan!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          cancelButtonText: 'Tidak',
          confirmButtonText: 'Ya',
        });

        if (result.isConfirmed) {
          await axiosPrivate.delete(`/reset/${reset_id}`);

          Swal.fire({
            icon: 'success',
            title: 'Pemohonan ditolak!',
            text: `Pemohonan ${reset_id} telah ditolak dan dipadam.`,
          }).then(() => {
            window.location.reload();
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Tiada akses',
          text: 'Anda tiada akses untuk menolak kelulusan',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while deleting the request.',
      });
    }
  };

  const approvedActions = (reset_id, userName, email) => {
    approveReset(reset_id, userName, email);
    changePwdDB(userName);
  };

  const renderStatusButtons = (reset_id, userName, status, email) => {
    if (status === 'Belum diluluskan') {
      return (
        <span>
          <Button
            variant="success"
            type="submit"
            name="lulus"
            value="Lulus"
            onClick={() => approvedActions(reset_id, userName, email)}
          >
            <FontAwesomeIcon icon={faCheck} />
          </Button>

          <Button
            variant="danger"
            type="submit"
            name="tolak"
            className="btn delete"
            value="Tolak"
            onClick={() => deleteReset(reset_id)}
          >
            <FontAwesomeIcon icon={faBan} />
          </Button>
        </span>
      );
    } else {
      return null;
    }
  };

  // Sorting
  const handleSort = () => {
    const sortedData = resets;
    sortedData.sort((a, b) => {
      const idA = new Date(a.request_time);
      const idB = new Date(b.request_time);
      return sort === 'asc' ? idA - idB : idB - idA;
    });

    setResets(sortedData);
    setSort(sort === 'asc' ? 'desc' : 'asc');
  };

  const filteredResets = resets.filter((reset) => {
    if (filterStatus === 'Belum diluluskan') {
      return reset.status !== 'Telah diluluskan';
    } else {
      return reset.status === 'Telah diluluskan';
    }
  });

  // Pagination
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const nPages = Math.ceil(filteredResets.length / recordsPerPage);
  const currentRecords = filteredResets.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  const handleExport = () => {
    const dataForExport = resets.map((reset) => ({
      'ID Pemohonan': reset.reset_id,
      Pemohon: reset.requestor,
      'Emel Pemohon': reset.email,
      'Masa Pemohonan': formatDate(reset.request_time),
      'ID Diminta': reset.target_id,
      'Status Kelulusan': reset.status,
      'Pemberi Kelulusan': reset.approver ? reset.approver : 'Belum diluluskan',
      'Masa Kelulusan': reset.approved_time
        ? formatDate(reset.approved_time)
        : 'Belum diluluskan',
    }));

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(dataForExport);

    const header = [
      'ID Pemohonan',
      'Pemohon',
      'Emel Pemohon',
      'Masa Pemohonan',
      'ID Diminta',
      'Status Kelulusan',
      'Pemberi Kelulusan',
      'Masa Kelulusan',
    ];
    XLSX.utils.sheet_add_aoa(ws, [header], { origin: 'A1' });

    XLSX.utils.book_append_sheet(wb, ws, 'Report');

    const currentDateTime = new Date();
    const formattedDate = `${currentDateTime.getFullYear()}-${(
      currentDateTime.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}-${currentDateTime
      .getDate()
      .toString()
      .padStart(2, '0')}`;

    XLSX.writeFile(
      wb,
      `Senarai-Pemohonan-Reset-Kata-Laluan_${formattedDate}.xlsx`
    );
  };

  let exportBtn = (
    <Button onClick={handleExport} variant="info">
      <span style={{ marginRight: '8px' }}>Export to Excel</span>
      <FontAwesomeIcon icon={faDownload} />
    </Button>
  );

  const handleDetail = (reset_id) => {
    navigate(`/pwd/${reset_id}`);
  };

  if (filteredResets?.length) {
    showContent = (
      <div>
        <Container>
          <Row>
            <Col md="auto">
              <Button variant="secondary" onClick={handleSort}>
                Susun ikut tarikh
                {sort === 'asc' ? (
                  <span>&#9650;</span> // Up arrow symbol
                ) : (
                  <span>&#9660;</span> // Down arrow symbol
                )}
              </Button>
            </Col>
            <Col md="auto">{auth?.groupID === 3 ? exportBtn : null}</Col>
            {/* <Col>
              <input type="search" id="gsearch" name="gsearch" />
              <input type="submit" onClick={() => findId(filteredActions)} />
            </Col> */}
          </Row>
        </Container>
        <table>
          <thead>
            <tr>
              <th scope="col">ID Reset</th>
              <th scope="col">Pemohon</th>
              <th scope="col">ID Diminta</th>
              <th scope="col">Status</th>
              <th scope="col">Masa Pemohonan</th>

              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {currentRecords.map((reset) => (
              <tr key={reset.reset_id}>
                <td>{reset.reset_id}</td>
                <td>{reset.requestor}</td>
                <td>{reset.target_id}</td>
                <td>{reset.status}</td>
                <td>{formatDate(reset.request_time)}</td>
                <td>
                  {renderStatusButtons(
                    reset.reset_id,
                    reset.target_id,
                    reset.status,
                    reset.email
                  )}

                  <Button
                    variant="dark"
                    type="submit"
                    name="detail"
                    value="detail"
                    onClick={() => handleDetail(reset.reset_id)}
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          ref={pageRef}
        />
      </div>
    );
  } else {
    showContent = (
      <Alert key="info" variant="info">
        Tiada pemohonan belum diluluskan.
      </Alert>
    );
  }

  if (resets?.length) {
    content = (
      <main>
        <div className="list">
          <h1>Senarai pemohonan reset kata laluan</h1>
          <label htmlFor="statusFilter">
            {' '}
            <span style={{ marginRight: '10px' }}>Status:</span>
          </label>
          <div style={{ display: 'inline-block', margin: '10px 0' }}>
            <Form.Select
              id="statusFilter"
              value={filterStatus}
              ref={filterRef}
              className="dropdown"
              onChange={(e) => {
                setFilterStatus(e.target.value);
                setCurrentPage(1);
              }}
            >
              <option value="Belum diluluskan">Belum diluluskan</option>
              <option value="Telah diluluskan">Telah diluluskan</option>
            </Form.Select>
          </div>

          {showContent}
        </div>
      </main>
    );
  } else {
    content = (
      <Alert key="info" variant="info">
        Tiada pemohonan yang dibuat.
      </Alert>
    );
  }

  return content;
};

export default ResetList;
