import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
  const [rangeSize, setRangeSize] = useState(15); // Default range size

  useEffect(() => {
    // Determine the range size based on screen width or number of pages
    const screenWidth = window.innerWidth;
    if (screenWidth < 768 || nPages <= 5) {
      setRangeSize(5); // Set range size to 5 on smaller screens or when there are fewer pages
    } else {
      setRangeSize(15); // Default range size
    }

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [nPages]);

  const handleResize = () => {
    // Handle screen size changes and update the range size accordingly
    const screenWidth = window.innerWidth;
    if (screenWidth < 768 || nPages <= 5) {
      setRangeSize(5);
    } else {
      setRangeSize(15);
    }
  };

  // Attach a resize event listener to adjust the range size dynamically
  useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, []);

  const startPage = Math.floor((currentPage - 1) / rangeSize) * rangeSize + 1;
  const endPage = Math.min(startPage + rangeSize - 1, nPages);

  const goToNextPage = () => {
    if (endPage < nPages) setCurrentPage(startPage + rangeSize);
  };

  const goToPrevPage = () => {
    if (startPage > 1) setCurrentPage(startPage - rangeSize);
  };

  return (
    <nav style={{ padding: '20px 0' }}>
      <ul className="pagination justify-content-center">
        <li className="page-item">
          <Link
            className="page-link"
            style={{ color: 'black' }}
            onClick={goToPrevPage}
            href="#"
          >
            Sebelum
          </Link>
        </li>
        {Array.from({ length: endPage - startPage + 1 }, (_, i) => (
          <li
            key={startPage + i}
            className={`page-item ${
              currentPage === startPage + i ? 'active' : ''
            }`}
          >
            <Link
              onClick={() => setCurrentPage(startPage + i)}
              className="page-link"
              style={{ color: 'black' }}
              href="#"
            >
              {startPage + i}
            </Link>
          </li>
        ))}
        <li className="page-item">
          <Link
            className="page-link"
            style={{ color: 'black' }}
            onClick={goToNextPage}
            href="#"
          >
            Selepas
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
