import React, { useState } from 'react';
import { Container, Form, Button, InputGroup } from 'react-bootstrap';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAuth from '../../hooks/useAuth';
import Swal from 'sweetalert2';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import HashPwd from './HashPwd';
import formatDate from '../formatDate';

const NewPwd = () => {
  const { auth } = useAuth();
  const [tempPwd, setTempPwd] = useState('');
  const [newPwd, setNewPwd] = useState('');
  const [tempPwdType, setTempPwdType] = useState('password');
  const [newPwdType, setNewPwdType] = useState('password');
  const axiosPrivate = useAxiosPrivate();
  const userName = auth?.userName;

  const onTempPwdChanged = (e) => {
    setTempPwd(e.target.value);
  };

  const onNewPwdChanged = (e) => {
    setNewPwd(e.target.value);
  };

  const toggleTempPassword = () => {
    tempPwdType === 'password'
      ? setTempPwdType('text')
      : setTempPwdType('password');
  };

  const toggleNewPassword = () => {
    newPwdType === 'password'
      ? setNewPwdType('text')
      : setNewPwdType('password');
  };

  const checkPwdValid = (password) => {
    if (password.length < 10) {
      return false;
    }

    if (!/[a-z]/.test(password)) {
      return false;
    }

    if (!/[A-Z]/.test(password)) {
      return false;
    }

    if (!/\d/.test(password)) {
      return false;
    }

if (/\s/.test(password)) {
  return false; // Contains whitespace
}

    if (!/[!@#$%^&*]/.test(password)) {
      return false;
    }

    // All conditions passed; the password is valid
    return true;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const today = new Date();
    const futureExpiry = new Date(today);
    futureExpiry.setMonth(today.getMonth() + 1);
        const isPasswordValid = checkPwdValid(newPwd);

    try {
      const response = await axiosPrivate.get(`/reset/pwd/${auth?.userName}`);
      const password = response?.data?.data[0].password;

      if (HashPwd(userName, tempPwd) === password) {
        if (isPasswordValid) {
          if (HashPwd(userName, newPwd) !== password) {
            await axiosPrivate.patch(`/reset/${auth?.userName}`, {
              password: HashPwd(userName, newPwd),
              pwdExpiry: futureExpiry,
            });
            Swal.fire({
              icon: 'success',
              title: 'Kata laluan ditukar',
              text: `Kata laluan anda telah ditukar dan akan tamat tempoh pada ${formatDate(
                futureExpiry
              )} `,
            }).then(() => {
              if (response.status === 200) {
                window.location.reload();
              }
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Ralat',
              text: 'Kata laluan tidak boleh sama dengan kata laluan sementara',
            });
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Ralat',
            text: 'Kata laluan mesti mempunyai 1 karakter khas, 1 huruf besar, dan 1 huruf kecil.  ',
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Ralat',
          text: 'Kata laluan sementara salah',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Ralat',
        text: 'Kata laluan tidak dapat ditukar. Sila cuba sekali lagi',
      });
      console.error('Error adding request:', error);
    }
  };

  const content = (
    <Container>
      <Form className="request-form" onSubmit={handleSubmit}>
        <h1> Reset Kata Laluan</h1>
        <h3>Pemohon:</h3>

        <Form.Control placeholder={auth?.userName} disabled />
 <Form.Text id="IDHelpBlock" muted>
           Pastikan ID tertera merupakan ID yang perlu ditukar kata laluan. Jika tidak, log masuk dengan ID tersebut.
        </Form.Text>
	  <h3>Kata Laluan Sementara:</h3>
        <InputGroup className="mb-1">
          <Form.Control
            placeholder="Kata laluan sementara"
            aria-label="Kata laluan sementara"
            aria-describedby="temp-pwd"
            type={tempPwdType}
            onChange={onTempPwdChanged}
            value={tempPwd}
            name="password"
            className="form-control"
          />
          <Button
            variant="outline-primary"
            onClick={toggleTempPassword}
            id="temp-pwd"
          >
            {tempPwdType === 'password' ? (
              <FontAwesomeIcon icon={faEyeSlash} />
            ) : (
              <FontAwesomeIcon icon={faEye} />
            )}
          </Button>
        </InputGroup>

        <h3>Kata Laluan Baru:</h3>
        <InputGroup className="mb-1">
          <Form.Control
            placeholder="Kata laluan baru"
            aria-label="Kata laluan baru"
            aria-describedby="new-pwd"
            type={newPwdType}
            onChange={onNewPwdChanged}
            value={newPwd}
            name="newPwd"
            className="form-control"
            maxLength={18}
            minLength={14}
          />

          <Button
            variant="outline-primary"
            onClick={toggleNewPassword}
            id="new-pwd"
          >
            {newPwdType === 'password' ? (
              <FontAwesomeIcon icon={faEyeSlash} />
            ) : (
              <FontAwesomeIcon icon={faEye} />
            )}
          </Button>
        </InputGroup>
        <Form.Text id="passwordHelpBlock" muted>
          Kata laluan mesti antara 14-18 karakter, mempunyai sekurang-kurangnya
          1 huruf kecil, 1 huruf besar, tiada 'space', dan 1 karakter khas.
        </Form.Text>
        <div style={{ margin: '50px 0' }}>
          <Button
            variant="success"
            style={{ width: '100%' }}
            size="md"
            type="submit"
          >
            Reset kata laluan
          </Button>
        </div>
      </Form>
    </Container>
  );
  return content;
};

export default NewPwd;
