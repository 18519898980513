import md5 from 'md5';

const HashPwd = (userName, password) => {
  const usernameNew = userName.toUpperCase();
  const newPassword = md5(`${password}${usernameNew}`).toUpperCase();

  return newPassword;
};

export default HashPwd;
