import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import formatDate from '../formatDate';

const ResetDetail = () => {
  const { reset_id } = useParams();
  const [resets, setResets] = useState([]);
  const [users, setUsers] = useState({
    requestorFullName: '',
    approverFullName: '',
    targetFullName: '',
  });

  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  let content;

  useEffect(() => {
    getReset();
  }, [reset_id]);

  const getReset = async () => {
    try {
      const response = await axiosPrivate.get(`/reset/${reset_id}`);
      const requestData = response.data;
      const { data } = requestData;
      setResets(data);
    } catch (error) {
      console.error('Error fetching requests:', error);
    }
  };

  const [reset] = resets;

  const getUserRequested = async (userName, propertyName) => {
    try {
      const requestBody = {
        userName: userName,
      };

      const response = await axiosPrivate.post('/pemohonan/user', requestBody);
      const fullName = response?.data?.data[0]?.fullName;
      setUsers((prevUserData) => ({
        ...prevUserData,
        [propertyName]: fullName || 'Belum diluluskan', // Set a default value if fullName is falsy
      }));
    } catch (error) {
      console.error('Error fetching user:', error);
    }
  };

  useEffect(() => {
    if (reset) {
      const approver = reset.approver;
      const targetId = reset.target_id;
      const requestor = reset.requestor;

      if (requestor) {
        getUserRequested(requestor, 'requestorFullName');
      }
      if (approver) {
        getUserRequested(approver, 'approverFullName');
      }

      if (targetId) {
        getUserRequested(targetId, 'targetFullName');
      }
    }
  }, [reset]);


  if (resets?.length) {
    const approveTimeFormatted = reset.approved_time
      ? formatDate(reset.approved_time)
      : 'Belum diluluskan';

    const approver = reset.approver ? reset.approver : 'Belum diluluskan';
    content = (
      <main>
        <div className="request-detail">
          <h1>Perihal Permohonan</h1>
          <div className="row">
            <div className="column">
              <h3>ID Permohonan </h3>
            </div>
            <div className="column">
              <p>{reset.reset_id}</p>
            </div>
          </div>
          <div className="row">
            <div className="column">
              <h3>Pemohon</h3>
            </div>

            <div className="column">
              <p>
                {reset.requestor} ({users.requestorFullName})
              </p>
            </div>
          </div>
          <div className="row">
            <div className="column">
              <h3>Emel Pemohon</h3>
            </div>

            <div className="column">
              <p>{reset.email}</p>
            </div>
          </div>
          <div className="row">
            <div className="column">
              <h3>Masa Pemohonan</h3>
            </div>
            <div className="column">
              <p>{formatDate(reset.request_time)}</p>
            </div>
          </div>

          <div className="row">
            <div className="column">
              <h3>ID Diminta</h3>
            </div>
            <div className="column">
              <p>
                {reset.target_id} ({users.targetFullName})
              </p>
            </div>
          </div>

          <div className="row">
            <div className="column">
              <h3>Status Kelulusan</h3>
            </div>
            <div className="column">
              <p>{reset.status}</p>
            </div>
          </div>
          <div className="row">
            <div className="column">
              <h3>Pemberi Kelulusan</h3>
            </div>
            <div className="column">
              <p>
                {approver
                  ? `${approver}  (${users.approverFullName})`
                  : 'Belum diluluskan'}
              </p>
            </div>
          </div>

          <div className="row">
            <div className="column">
              <h3>Masa Kelulusan</h3>
            </div>
            <div className="column">
              <p>{approveTimeFormatted}</p>
            </div>
          </div>
          <Button
            variant="dark"
            type="submit"
            name="detail"
            value="detail"
            size="lg"
            style={{ width: '100%', fontSize: '16px' }}
            onClick={() => navigate(-1)}
          >
            Kembali
          </Button>
        </div>
      </main>
    );
  } else {
    content = <p>ID tidak wujud</p>;
  }

  return content;
};

export default ResetDetail;
