import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const Unauthorised = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    // <section>
    //   <h1>Unauthorized</h1>
    //   <br />
    //   <p>You do not have access to the requested page.</p>
    //   <div className="flexGrow">
    //     <button onClick={goBack}>Go Back</button>
    //   </div>
    // </section>
    <div
      className="modal show"
      style={{ display: 'block', position: 'initial' }}
    >
      <Modal.Dialog>
        <Modal.Header closeButton>
          <Modal.Title>Halaman tidak boleh diakses</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Halaman ini hanya boleh diakses oleh pemberi kelulusan sahaja</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="dark" onClick={goBack}>
            Kembali
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
    </div>
  );
};

export default Unauthorised;
