import { Routes, Route } from 'react-router-dom';
import React from 'react';
import Layout from './components/Layout';
import Login from './components/Login';
import DashLayout from './components/DashLayout';
import Dashboard from './features/auth/Dashboard';
import PersistLogin from './components/PersistLogin';
import ActionList from './features/action/ActionList';
import ActiveId from './components/ActiveId';

// Request
import RequestForm from './features/request/RequestForm';
import RequestList from './features/request/RequestList';
import RequestDetail from './features/request/RequestDetail';
import Unauthorised from './components/Unauthorised';
import RequireAuth from './components/RequireAuth';

// Reset password
import ResetForm from './features/auth/ResetForm';
import NewPwd from './features/auth/NewPwd';
import ResetList from './features/auth/ResetList';
import ResetDetail from './features/auth/ResetDetail';

const ROLES = {
  Pemohon: [1, 2],
  Lulus: [3, 4, 5, 6, 7, 8, 9, 10],
};

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="login" element={<Login />} />
        <Route path="unauthorized" element={<Unauthorised />} />

        <Route element={<PersistLogin />}>
          <Route
            element={
              <RequireAuth allowedGroups={[ROLES.Pemohon, ROLES.Lulus]} />
            }
          >
            <Route path="/" element={<DashLayout />}>
              <Route index element={<Dashboard />} />
              <Route path="mohon">
                <Route index element={<RequestForm />} />
              </Route>
              <Route path="reset">
                <Route index element={<ResetForm />} />
              </Route>
              <Route path="newPwd">
                <Route index element={<NewPwd />} />
              </Route>
              <Route element={<RequireAuth allowedGroups={[ROLES.Lulus]} />}>
                <Route path="tindakan">
                  <Route index element={<ActionList />} />
                </Route>
                <Route path="aktif">
                  <Route index element={<ActiveId />} />
                </Route>
                <Route path="pwd">
                  <Route index element={<ResetList />} />
                  <Route path=":reset_id" element={<ResetDetail />} />
                </Route>

                <Route path="pemohonan">
                  <Route index element={<RequestList />} />
                  <Route path=":request_id" element={<RequestDetail />} />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
