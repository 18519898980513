const formatDate = (dateString) => {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'Asia/Kuala_Lumpur',
    hour12: true,
  };
  return new Date(dateString).toLocaleString('ms-MY', options);
};

export default formatDate;
