import React, { useState, useEffect, useRef } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { PELULUS } from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faDownload } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Container, Row, Col, Form } from 'react-bootstrap';
import Pagination from '../../components/Pagination';
import Swal from 'sweetalert2';
import useLocalStorage from '../../hooks/useLocalStorage';
import * as XLSX from 'xlsx';
import formatDate from '../formatDate';

const ActionList = () => {
  const { auth } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const filterRef = useRef();
  const pageRef = useRef();

  const [actions, setActions] = useState([]);
  const [currentPage, setCurrentPage] = useLocalStorage('actionPage', 1);
  const axiosPrivate = useAxiosPrivate();
  const [recordsPerPage] = useState(10);

  const [filterStatus, setFilterStatus] = useLocalStorage(
    'actStatus',
    'Tindakan belum diambil'
  );
  const [sort, setSort] = useState('asc');
  const [searchId, setSearchId] = useState('');

  let content;
  let filteredActions;
  let showContent;

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getActions = async () => {
      try {
        const response = await axiosPrivate.get('/tindakan', {
          signal: controller.signal,
        });

        const requestData = response.data;
        const { data } = requestData;
        isMounted && setActions(data);
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching actions:', error);
          navigate('/login', { state: { from: location }, replace: true });
        }
      }
    };

    getActions();

    return () => {
      isMounted = false;
      if (!controller.signal.aborted) {
        controller.abort();
      }
    };
  }, []);

  const deleteAction = async (action_id) => {
    try {
      if (PELULUS.includes(auth?.userName)) {
        const result = await Swal.fire({
          title: 'Batalkan tindakan ini?',
          text: 'Tindakan ini akan dibatalkan dan dipadam jika diteruskan!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          cancelButtonText: 'Tidak',
          confirmButtonText: 'Ya',
        });

        if (result.isConfirmed) {
          await axiosPrivate.delete(`tindakan/${action_id}`);

          Swal.fire({
            icon: 'success',
            title: 'Tindakan dibatalkan!',
            text: `Tindakan ${action_id} telah dibatalkan dan dipadam.`,
          }).then(() => {
            window.location.reload();
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Tiada akses',
          text: 'Anda tiada akses untuk membatalkan tindakan ini.',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while deleting the request.',
      });
    }
  };

  const renderStatusButtons = (action_id, status) => {
    if (status === 'Tindakan belum diambil') {
      return (
        <span>
          <Button
            variant="danger"
            type="submit"
            name="batal"
            value="Batal"
            onClick={() => deleteAction(action_id)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </span>
      );
    } else {
      return null;
    }
  };

  const handleSort = () => {
    const sortedData = actions;
    sortedData.sort((a, b) => {
      const idA = new Date(a.schedule);
      const idB = new Date(b.schedule);
      return sort === 'asc' ? idA - idB : idB - idA;
    });

    setActions(sortedData);
    setSort(sort === 'asc' ? 'desc' : 'asc');
  };

  filteredActions = actions.filter((action) => {
    if (filterStatus === 'Tindakan belum diambil') {
      return action.status !== 'Tindakan telah diambil';
    } else {
      return action.status === 'Tindakan telah diambil';
    }
  });

  // Sorting functions
  const findId = (actions) => {
    const filterIds = actions.filter((action) => {
      return action.target_id === searchId;
    });
    return filterIds;
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const nPages = Math.ceil(filteredActions.length / recordsPerPage);
  const currentRecords = filteredActions.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  // Export to excel
  const handleExport = () => {
    const dataForExport = actions.map((action) => ({
      'ID Tindakan': action.action_id,
      'ID Pemohonan': action.request_id,
      Tindakan: action.action === 1 ? 'Buka' : 'Tutup',
      'ID Diminta': action.target_id,
      Jadual: formatDate(action.schedule),
      Status: action.status,
      'Masa Perlaksanaan': action.execution_time
        ? formatDate(action.execution_time)
        : 'Belum dilaksanakan',
    }));

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(dataForExport);

    const header = [
      'ID Tindakan',
      'ID Pemohonan',
      'Tindakan',
      'ID Diminta',
      'Jadual',
      'Status',
      'Masa Perlaksanaan',
    ];
    XLSX.utils.sheet_add_aoa(ws, [header], { origin: 'A1' });

    XLSX.utils.book_append_sheet(wb, ws, 'Report');

    const currentDateTime = new Date();
    const formattedDate = `${currentDateTime.getFullYear()}-${(
      currentDateTime.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}-${currentDateTime
      .getDate()
      .toString()
      .padStart(2, '0')}`;

    XLSX.writeFile(wb, `Senarai-Tindakan-ID-Pengganti_${formattedDate}.xlsx`);
  };

  let exportBtn = (
    <Button onClick={handleExport} variant="info">
      <span style={{ marginRight: '8px' }}>Export to Excel</span>
      <FontAwesomeIcon icon={faDownload} />
    </Button>
  );

  if (filteredActions?.length) {
    showContent = (
      <div>
        <Container>
          <Row>
            <Col md="auto">
              <Button variant="secondary" onClick={handleSort}>
                Susun ikut tarikh
                {sort === 'asc' ? (
                  <span>&#9650;</span> // Up arrow symbol
                ) : (
                  <span>&#9660;</span> // Down arrow symbol
                )}
              </Button>
            </Col>
            <Col md="auto">{auth?.groupID === 3 ? exportBtn : null}</Col>
            {/* <Col>
              <input type="search" id="gsearch" name="gsearch" />
              <input type="submit" onClick={() => findId(filteredActions)} />
            </Col> */}
          </Row>
        </Container>

        <table>
          <thead>
            <tr>
              <th>ID Tindakan</th>
              <th>ID Pemohonan</th>
              <th>Tindakan</th>
              <th>ID Diminta</th>
              <th>Jadual</th>
              <th>Status</th>
              <th>Masa Perlaksanaan</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {currentRecords.map((action) => (
              <tr key={action.action_id}>
                <td>{action.action_id}</td>
                <td>{action.request_id}</td>
                <td>{action.action === 1 ? 'Buka' : 'Tutup'}</td>
                <td>{action.target_id}</td>
                <td>{formatDate(action.schedule)}</td>
                <td>{action.status}</td>
                <td>
                  {action.execution_time
                    ? formatDate(action.execution_time)
                    : 'Belum dilaksanakan'}
                </td>

                <td>{renderStatusButtons(action.action_id, action.status)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          ref={pageRef}
        />
      </div>
    );
  } else {
    showContent = (
      <Alert key="info" variant="info">
        Tiada tindakan belum diambil
      </Alert>
    );
  }

  content = (
    <main>
      <div className="list">
        <h1>Senarai Tindakan bagi Pengganti ArRahnu</h1>

        <label htmlFor="statusFilter">
          <span style={{ marginRight: '10px' }}>Status:</span>
        </label>
        <div style={{ display: 'inline-block', margin: '10px 0' }}>
          <Form.Select
            id="statusFilter"
            value={filterStatus}
            className="dropdown"
            ref={filterRef}
            onChange={(e) => {
              setFilterStatus(e.target.value);
              setCurrentPage(1);
            }}
          >
            <option value="Tindakan belum diambil">
              Tindakan belum diambil
            </option>
            <option value="Tindakan telah diambil">
              Tindakan telah diambil
            </option>
          </Form.Select>
        </div>

        {showContent}
      </div>
    </main>
  );

  return content;
};

export default ActionList;
