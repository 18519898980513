import { Outlet } from 'react-router-dom';
import DashHeader from './DashHeader';
import Pos from '../assets/pos_logo.png';
import ArRahnu from '../assets/pos_arrahnu.png';
import React from 'react';

const DashLayout = () => {
  return (
    <div className="wrapper">
      <DashHeader />
      <div className="main_content">
        <div className="header">
          <img src={Pos} alt="Pos Malaysia" />
          <img src={ArRahnu} style={{ width: '200px' }} alt="ArRahnu" />
        </div>
        <div className="info">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DashLayout;
