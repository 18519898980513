import React, { useState, useEffect, useRef } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { Button, Alert } from 'react-bootstrap';
import Pagination from '../../components/Pagination';
import useLocalStorage from '../../hooks/useLocalStorage';

const Dashboard = () => {
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [requests, setRequests] = useState([]);
  const [sort, setSort] = useState('asc');
  const [recordsPerPage] = useState(10);

  const pageRef = useRef();

  const [currentPage, setCurrentPage] = useLocalStorage('reqPage', 1);

  let content;
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getRequests = async () => {
      try {
        const response = await axiosPrivate.post(
          '/pemohonan/action',
          {
            requestor: auth?.userName,
          },
          {
            signal: controller.signal,
          }
        );
        const requestData = response.data;
        const { data } = requestData;
        isMounted && setRequests(data);
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching requests:', error);
          navigate('/login', { state: { from: location }, replace: true });
        }
      }
    };
    getRequests();

    return () => {
      isMounted = false;
      if (!controller.signal.aborted) {
        controller.abort();
      }
    };
  }, []);

  const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };
    return new Date(dateString).toLocaleString('ms-MY', options);
  };

  // Sorting
  const handleSort = () => {
    const sortedData = requests;
    sortedData.sort((a, b) => {
      const idA = new Date(a.request_time);
      const idB = new Date(b.request_time);
      return sort === 'asc' ? idA - idB : idB - idA;
    });

    setRequests(sortedData);
    setSort(sort === 'asc' ? 'desc' : 'asc');
  };

  // Pagination
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const nPages = Math.ceil(requests.length / recordsPerPage);
  const currentRecords = requests.slice(indexOfFirstRecord, indexOfLastRecord);

  if (requests?.length) {
    content = (
      <div>
        <p>Senarai pemohonan yang dibuat:</p>
        <div>
          <Button variant="secondary" onClick={handleSort}>
            Susun ikut tarikh
            {sort === 'asc' ? (
              <span>&#9650;</span> // Up arrow symbol
            ) : (
              <span>&#9660;</span> // Down arrow symbol
            )}
          </Button>
        </div>
        <table>
          <thead>
            <tr>
              <th scope="col">ID Pemohonan</th>

              <th scope="col">ID Diminta</th>
              <th scope="col">Status Pemohonan</th>
              <th scope="col">Tindakan </th>
              <th scope="col">Status Tindakan</th>
              <th scope="col">Masa Tindakan</th>
            </tr>
          </thead>
          <tbody>
            {currentRecords.map((request) => (
              <tr key={request.action_id}>
                <td>{request.request_id}</td>
                <td>{request.target_id}</td>
                <td>{request.status[0]}</td>
                <td>{request.action === 1 ? 'Buka' : 'Tutup'}</td>
                <td>{request.status[1]}</td>
                <td>
                  {formatDate(request.schedule)
                    ? formatDate(request.schedule)
                    : 'Tindakan belum diambil'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          ref={pageRef}
        />
      </div>
    );
  } else {
    content = (
      <Alert key="info" variant="info">
        Tiada pemohonan yang telah dibuat.
      </Alert>
    );
  }
  const allContent = (
    <section>
      <h1>Selamat Datang!</h1>

      {content}
    </section>
  );

  return allContent;
};

export default Dashboard;
