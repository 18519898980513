import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const RequestDetail = () => {
  const { request_id } = useParams();

  const [requests, setRequests] = useState([]);
  const [users, setUsers] = useState({
    requestorFullName: '',
    approverFullName: '',
    targetFullName: '',
  });
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  let content;

  useEffect(() => {
    getRequest();
  }, [request_id]);

  const getUserRequested = async (userName, propertyName) => {
    try {
      const requestBody = {
        userName: userName,
      };

      const response = await axiosPrivate.post('/pemohonan/user', requestBody);
      const fullName = response?.data?.data[0]?.fullName;
      setUsers((prevUserData) => ({
        ...prevUserData,
        [propertyName]: fullName || 'Belum diluluskan', // Set a default value if fullName is falsy
      }));
    } catch (error) {
      console.error('Error fetching user:', error);
    }
  };

  const getRequest = async () => {
    try {
      const response = await axiosPrivate.get(`/pemohonan/${request_id}`);
      const requestData = response.data;
      const { data } = requestData;
      setRequests(data);
    } catch (error) {
      console.error('Error fetching requests:', error);
    }
  };

  const [request] = requests;

  useEffect(() => {
    if (request) {
      const approver = request.approver;
      const targetId = request.target_id;
      const requestor = request.requestor;

      if (requestor) {
        getUserRequested(requestor, 'requestorFullName');
      }
      if (approver) {
        getUserRequested(approver, 'approverFullName');
      }

      if (targetId) {
        getUserRequested(targetId, 'targetFullName');
      }
    }
  }, [request]);

  const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'Asia/Kuala_Lumpur',
      hour12: true,
    };
    return new Date(dateString).toLocaleString('ms-MY', options);
  };

  if (requests?.length) {
    const approveTimeFormatted = request.approve_time
      ? formatDate(request.approve_time)
      : 'Belum diluluskan';

    const approver = request.approver ? request.approver : 'Belum diluluskan';
    content = (
      <main>
        <div className="request-detail">
          <h1>Perihal Permohonan</h1>
          <div className="row">
            <div className="column">
              <h3>ID Permohonan </h3>
            </div>
            <div className="column">
              <p>{request.request_id}</p>
            </div>
          </div>
          <div className="row">
            <div className="column">
              <h3>Pemohon</h3>
            </div>

            <div className="column">
              <p>
                {request.requestor} ({users.requestorFullName})
              </p>
            </div>
          </div>
          <div className="row">
            <div className="column">
              <h3>Masa Bertindak</h3>
            </div>
            <div className="column">
              <p>{formatDate(request.date_execute)}</p>
            </div>
          </div>

          <div className="row">
            <div className="column">
              <h3>ID Diminta</h3>
            </div>
            <div className="column">
              <p>
                {request.target_id} ({users.targetFullName})
              </p>
            </div>
          </div>

          <div className="row">
            <div className="column">
              <h3>Masa Pemohonan</h3>
            </div>
            <div className="column">
              <p>{formatDate(request.request_time)}</p>
            </div>
          </div>
          <div className="row">
            <div className="column">
              <h3>Tindakan</h3>
            </div>
            <div className="column">
              <p>{request.action === 1 ? 'Buka' : 'Tutup'}</p>
            </div>
          </div>
          <div className="row">
            <div className="column">
              <h3>Sebab Pemohonan</h3>
            </div>
            <div className="column">
              <p>{request.reason}</p>
            </div>
          </div>
          <div className="row">
            <div className="column">
              <h3>Status Kelulusan</h3>
            </div>
            <div className="column">
              <p>{request.status}</p>
            </div>
          </div>
          <div className="row">
            <div className="column">
              <h3>Pemberi Kelulusan</h3>
            </div>
            <div className="column">
              <p>
                {approver
                  ? `${approver}  (${users.approverFullName})`
                  : 'Belum diluluskan'}
              </p>
            </div>
          </div>

          <div className="row">
            <div className="column">
              <h3>Masa Kelulusan</h3>
            </div>
            <div className="column">
              <p>{approveTimeFormatted}</p>
            </div>
          </div>
          <Button
            variant="dark"
            type="submit"
            name="detail"
            value="detail"
            size="lg"
            style={{ width: '100%', fontSize: '16px' }}
            onClick={() => navigate(-1)}
          >
            Kembali
          </Button>
        </div>
      </main>
    );
  } else {
    content = <p>ID tidak wujud</p>;
  }

  return content;
};

export default RequestDetail;
