import { useRef, useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from '../api/axios';
import { Alert } from 'react-bootstrap';
import useInput from '../hooks/useInput';
import useToggle from '../hooks/useToggle';
import Pos from '../assets/pos_logo.png';
import ArRahnu from '../assets/pos_arrahnu.png';

const LOGIN_URL = '/login';

const Login = () => {
  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const userRef = useRef();
  const errRef = useRef();

  const [userName, resetUser, userAttribs] = useInput('user', '');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');

  useToggle('persist', true);

  useEffect(() => {
    userRef?.current?.focus();
  }, []);

  useEffect(() => {
    setErrMsg('');
  }, [userName, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let pwdExpiry;
    const today = new Date();

    try {
      const response = await axios.post(
        LOGIN_URL,
        {
          userName: userName,
          password: pwd,
        },
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      );

      const accessToken = response?.data?.accessToken;
      const groupID = response?.data?.groupID;
      pwdExpiry = new Date(response?.data?.pwdExpiry);

      if (pwdExpiry < today) {
        setErrMsg(
          `Kata laluan telah tamat tempoh pada ${pwdExpiry.toLocaleDateString()}`
        );
      } else {
        setAuth({ userName, pwd, groupID, pwdExpiry, accessToken });
        resetUser('');
        setPwd('');
        navigate(from, { replace: true });
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg('Tiada respon daripada pelayan');
      } else if (err.response?.status === 400) {
        setErrMsg('Tiada nama pengguna atau kata laluan');
      } else if (err.response?.status === 401) {
        setErrMsg('Kata laluan atau ID tidak aktif');
      } else {
        setErrMsg('Log masuk gagal');
      }
      errRef?.current?.focus();
    }
  };

  return (
    <section className="login-form">
      <div className="login-content">
        <div className="login-logo">
          <img style={{ width: '150px' }} src={Pos} alt="Pos Malaysia" />
          <img src={ArRahnu} alt="Pos ArRahnu" />
        </div>

        <h1>Log Masuk</h1>
        <form onSubmit={handleSubmit}>
          <label htmlFor="username">Nama Pengguna:</label>
          <input
            type="text"
            id="username"
            ref={userRef}
            placeholder="Nama Pengguna"
            autoComplete="off"
            {...userAttribs}
            className="login-field"
            required
          />
          <label htmlFor="password">Kata Laluan:</label>
          <input
            type="password"
            id="password"
            placeholder="Kata Laluan"
            onChange={(e) => setPwd(e.target.value)}
            value={pwd}
            className="login-field"
            maxLength="18"
            required
          />

          <button className="btn login">Log masuk</button>
          {/* <Link to="/reset">
            <p className="pwdExpired">Reset kata laluan</p>
          </Link> */}
          {errMsg ? (
            <Alert key="danger" variant="danger" ref={errRef}>
              {errMsg}
            </Alert>
          ) : (
            <div></div>
          )}
        </form>
      </div>
    </section>
  );
};

export default Login;
