import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const ActiveId = () => {
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();
  const navigate = useNavigate();
  const [activeIds, setActiveIds] = useState([]);

  let content;

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getActiveIds = async () => {
      try {
        const response = await axiosPrivate.get('/users/active', {
          signal: controller.signal,
        });
        const requestData = response.data;
        const { data } = requestData;
        isMounted && setActiveIds(data);
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching requests:', error);
          navigate('/login', { state: { from: location }, replace: true });
        }
      }
    };
    getActiveIds();

    return () => {
      isMounted = false;
      if (!controller.signal.aborted) {
        controller.abort();
      }
    };
  }, []);

  const calcDays = (date) => {
    const givenDate = new Date(date);
    const currentDate = new Date();

    if (givenDate.toDateString() === currentDate.toDateString()) {
      return 0;
    }

    const timeDifference = currentDate.getTime() - givenDate.getTime();

    const daysDifference = Math.floor(
      timeDifference / (1000 * 60 * 60 * 24) + 1
    );

    return daysDifference;
  };

  const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };
    const date = new Date(dateString);
    const offset = date.getTimezoneOffset() / 60;
    date.setHours(date.getHours() + offset);
    return date.toLocaleString('ms-MY', options);
  };

  if (activeIds?.length) {
    content = (
      <main>
        <div className="list">
          <h1>Senarai ID Pengganti yang aktif</h1>
          <table>
            <thead>
              <tr>
                <th scope="col">ID Pengganti</th>
                <th scope="col">Masa Terakhir Aktif</th>
                <th scope="col">Tempoh Aktif</th>
              </tr>
            </thead>
            <tbody>
              {activeIds.map((activeId) => (
                <tr key={activeId.userName}>
                  <td>{activeId.userName}</td>
                  <td>{formatDate(activeId.lastLogin)}</td>
                  <td>{calcDays(activeId.lastLogin)} hari</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </main>
    );
  }

  return content;
};

export default ActiveId;
