import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useLogout from '../hooks/useLogout';
import {
  faUserPlus,
  faUnlock,
  faListOl,
  faHome,
  faEdit,
  faList,
  faTasks,
  faKey,
  faSignOut,
} from '@fortawesome/free-solid-svg-icons';

const DashHeader = () => {
  const navigate = useNavigate();
  const logout = useLogout();

  const signOut = async () => {
    await logout();
    navigate('/login');
  };

  const iconStyle = {
    marginRight: '8px',
  };

  const content = (
    <>
      <div className="sidebar">
        <ul>
          <li>
            <Link to="/">
              <FontAwesomeIcon icon={faHome} style={iconStyle} />
              <span>Utama</span>
            </Link>
          </li>
          <li>
            <Link to="/mohon">
              <FontAwesomeIcon icon={faEdit} style={iconStyle} />
              <span>Buat Pemohonan</span>
            </Link>
          </li>
          <li>
            <Link to="/reset">
              <FontAwesomeIcon icon={faKey} style={iconStyle} />
              <span>Mohon Tukar Kata Laluan</span>
            </Link>
          </li>
          <li>
            <Link to="/newPwd">
              <FontAwesomeIcon icon={faUnlock} style={iconStyle} />
              <span>Reset Kata Laluan</span>
            </Link>
          </li>
          <li>
            <Link to="/pemohonan">
              <FontAwesomeIcon icon={faList} style={iconStyle} />
              <span>Senarai Pemohonan</span>
            </Link>
          </li>
          <li>
            <Link to="/tindakan">
              <FontAwesomeIcon icon={faTasks} style={iconStyle} />
              <span>Senarai Tindakan</span>
            </Link>
          </li>

          <li>
            <Link to="/pwd">
              <FontAwesomeIcon icon={faListOl} style={iconStyle} />{' '}
              <span>Senarai Reset Kata Laluan</span>
            </Link>
          </li>
          <li>
            <Link to="/aktif">
              <FontAwesomeIcon icon={faUserPlus} style={iconStyle} />
              <span>Senarai ID Pengganti Aktif</span>
            </Link>
          </li>
          <li onClick={signOut}>
            <FontAwesomeIcon icon={faSignOut} style={iconStyle} />
            <span>Log Keluar</span>
          </li>
        </ul>
      </div>
    </>
  );

  return content;
};

export default DashHeader;
