import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Select from 'react-select';
import useAuth from '../../hooks/useAuth';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const ResetForm = () => {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [targetId, setTargetId] = useState([]);
  const [email, setEmail] = useState('');
  const [fields, setFields] = useState([
    { target_id: '', action: '', selectedOption: null },
  ]);

  const getIds = async () => {
    try {
      const response = await axiosPrivate.get('/users/branch');
      const data = response?.data?.data;
      const options = data.map((user) => ({
        value: user.userName,
        label: `${user.userName} (${user.fullName})`,
        email: user?.email,
      }));
      setTargetId(options);
    } catch (error) {
      console.error('Error fetching requests:', error);
    }
  };

  useEffect(() => {
    getIds();
  }, []);

  const handleAddFields = () => {
    setFields([...fields, { target_id: '', action: '', selectedOption: null }]);
  };

  const handleDeleteField = (index) => {
    const updatedFields = [...fields];
    updatedFields.splice(index, 1);
    setFields(updatedFields);
  };

  const handleIDChange = (selectedOption, index) => {
    const newFields = [...fields];
    newFields[index].selectedOption = selectedOption;
    setFields(newFields);
    setEmail(selectedOption?.email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const status = 'Belum diluluskan';
    try {
      for (const field of fields) {
        const requestData = {
          requestor: auth?.userName,
          email: email,
          request_time: new Date(),
          target_id: field.selectedOption?.value,
          status: status,
        };

        if (!field.selectedOption) {
          Swal.fire({
            icon: 'error',
            title: 'Ralat',
            text: 'Sila pilih ID yang perlu ditukar kata laluan.',
          });
          return;
        }

        await axiosPrivate.post('/resetReq', requestData);
      }
      sendEmail();
      Swal.fire({
        icon: 'success',
        title: 'Pemohonan dihantar',
        text: 'Pemohonan telah ditambah dan dihantar.',
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Ralat',
        text: 'Pemohonan tidak dapat ditambah',
      });
      console.error('Error adding request:', error);
    }
  };

  // Send emails
  const sendEmail = async () => {
    try {
      const emailData = {
        request_time: new Date(),
      };
      await axiosPrivate.post('/email/approvePwd', emailData);
    } catch (err) {
      console.error(`${err}: Email is not sent`);
    }
  };

  const content = (
    <Container>
      <Form
        className="request-form"
        onSubmit={handleSubmit}
        style={{ textAlign: 'center' }}
      >
        <h1>Pemohonan Reset Kata Laluan</h1>

        <h3>Pemohon:</h3>

        <Form.Control placeholder={auth?.userName} disabled />

        <Button
          variant="primary"
          style={{ fontSize: '16px', marginTop: '20px' }}
          size="md"
          onClick={handleAddFields}
        >
          Tambah ID
        </Button>
        <div>
          {fields.map((val, i) => (
            <Row
              key={i}
              style={{
                display: 'flex',
                marginTop: '30px ',
                justifyContent: 'center',
              }}
            >
              <Col>
                <Select
                  options={targetId}
                  value={val.selectedOption}
                  onChange={(selectedOption) =>
                    handleIDChange(selectedOption, i)
                  }
                  isClearable
                  placeholder="Pilih ID"
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      height: '60px',

                      textAlign: 'center',
                    }),
                  }}
                />
              </Col>
              <Col md={1}>
                <Button
                  variant="danger"
                  size="lg"
                  onClick={() => handleDeleteField(i)}
                >
                  {' '}
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
              </Col>
            </Row>
          ))}
        </div>

        <Button
          variant="success"
          style={{ width: '100%' }}
          size="md"
          type="submit"
        >
          Hantar
        </Button>
      </Form>
    </Container>
  );
  return content;
};

export default ResetForm;
