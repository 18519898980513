import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Select from 'react-select';
import useAuth from '../../hooks/useAuth';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const RequestForm = () => {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const [targetId, setTargetId] = useState([]);
  const [date, setDate] = useState('');
  const [reason, setReason] = useState('');
  const [fields, setFields] = useState([
    { target_id: '', action: '', selectedOption: null },
  ]);

  const onActionChanged = (e, index) => {
    const newFields = [...fields];
    newFields[index].action = e.target.value;
    setFields(newFields);
  };

  const onDateChanged = (e) => {
    setDate(e.target.value);
  };
  const onReasonChanged = (e) => setReason(e.target.value);

  useEffect(() => {
    getIds();
  }, []);

  const getIds = async () => {
    try {
      const response = await axiosPrivate.get('/users');
      const data = response?.data?.data;
      const options = data?.map((user) => ({
        value: user.userName,
        label: `${user.userName} (${user.fullName})`,
      }));

      setTargetId(options);
    } catch (error) {
      console.error('Error fetching requests:', error);
    }
  };

  const handleIDChange = (selectedOption, index) => {
    const newFields = [...fields];
    newFields[index].selectedOption = selectedOption;
    setFields(newFields);
  };

  const handleDeleteField = (index) => {
    const updatedFields = [...fields];
    updatedFields.splice(index, 1);
    setFields(updatedFields);
  };

  const handleAddFields = () => {
    setFields([...fields, { target_id: '', action: '', selectedOption: null }]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      for (const field of fields) {
        const requestData = {
          requestor: auth?.userName,
          date_execute: new Date(date).toISOString(),
          reason: reason,
          action: field.action,
          target_id: field.selectedOption?.value,
          status: 'Belum diluluskan',
          request_time: new Date(),
        };

        if (!date || !reason || !field.action || !field.selectedOption) {
          Swal.fire({
            icon: 'error',
            title: 'Ralat',
            text: 'Sila isi semua maklumat untuk setiap tindakan dan ID.',
          });
          return;
        }
        await axiosPrivate.post('/pemohonan', requestData);
      }
      sendEmail(date);
      Swal.fire({
        icon: 'success',
        title: 'Pemohonan dihantar',
        text: 'Pemohonan telah ditambah dan dihantar.',
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Ralat',
        text: 'Pemohonan tidak dapat ditambah',
      });
      console.error('Error adding request:', error);
    }
  };

  // Send emails
  const sendEmail = async () => {
    try {
      const emailData = {
        request_time: new Date().toISOString(),
      };
      await axiosPrivate.post('/email/requestEmail', emailData);
    } catch (err) {
      console.error(`${err}: Email is not sent`);
    }
  };

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const currentDay = currentDate.getDate().toString().padStart(2, '0');
  const currentHour = currentDate.getHours().toString().padStart(2, '0');
  const currentMinute = currentDate.getMinutes().toString().padStart(2, '0');
  const minDate = `${currentYear}-${currentMonth}-${currentDay}T${currentHour}:${currentMinute}`;

  const content = (
    <Container>
      <Form
        className="request-form"
        style={{ textAlign: 'center' }}
        onSubmit={handleSubmit}
      >
        <h1>Pemohonan ID Pengganti ArRahnu</h1>

        <h3>Pemohon:</h3>
        {/* 
        <div className="request-field requestor">{auth?.userName}</div> */}
        <Form.Control placeholder={auth?.userName} disabled />
        <h3>Tarikh Perlaksanaan:</h3>
        <Form.Control
          type="datetime-local"
          id="date"
          className="request-field"
          name="date"
          value={date}
          onChange={onDateChanged}
          min={minDate}
        />

        <h3>Sebab:</h3>
        <Form.Control
          type="text"
          placeholder="Sebab permohonan"
          name="reason"
          className="request-field"
          value={reason}
          onChange={onReasonChanged}
          required
        />
        <Button
          variant="primary"
          style={{ fontSize: '16px', margin: '10px' }}
          size="md"
          onClick={handleAddFields}
        >
          Tambah Tindakan dan ID
        </Button>
        {fields.map((val, i) => (
          <Container>
            <Row
              key={i}
              style={{
                display: 'flex',
                marginTop: '30px ',
                justifyContent: 'center',
              }}
            >
              <Col sm={4}>
                <Form.Control
                  as="select"
                  style={{
                    padding: '18px 0',
                    textAlign: 'center',
                  }}
                  onChange={(e) => onActionChanged(e, i)}
                  value={val.action}
                >
                  <option value="" disabled>
                    (Buka/Tutup)
                  </option>
                  <option value={1}>Buka</option>
                  <option value={0}>Tutup</option>
                </Form.Control>
              </Col>
              <Col sm={6}>
                {' '}
                <Select
                  options={targetId}
                  value={val.selectedOption}
                  onChange={(selectedOption) =>
                    handleIDChange(selectedOption, i)
                  }
                  isClearable
                  placeholder="Pilih ID"
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      height: '60px',

                      textAlign: 'center',
                    }),
                  }}
                />
              </Col>
              <Col sm={2}>
                <Button
                  variant="danger"
                  size="lg"
                  onClick={() => handleDeleteField(i)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
              </Col>
            </Row>
          </Container>
        ))}

        <Button
          variant="success"
          style={{ width: '100%' }}
          size="lg"
          type="submit"
        >
          Hantar
        </Button>
      </Form>
    </Container>
  );
  return content;
};

export default RequestForm;
